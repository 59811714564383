// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcF4mhfIOtPkReoKLVwpFu6-Ll-aYZI74",
  authDomain: "aincard-344e4.firebaseapp.com",
  projectId: "aincard-344e4",
  storageBucket: "aincard-344e4.appspot.com",
  messagingSenderId: "178806336856",
  appId: "1:178806336856:web:3e565325eb6723d3ec892d",
  measurementId: "G-8E7G1B98ER"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore };